import React from "react"
import Layout from '../../components/layout'
import Section from '../../components/section'
import Banner from '../../components/banner'
import Container from '../../components/container'
import Typography from '../../components/typography'
import Seo from '../../components/seo';

export default function Build(props) {
  return (
    <Layout {...props}>
      <Seo title="Build" description="From the conceptualization stage until finishing stage and acceptance of the client, the company gives exemplary general construction services." />
      <Section>
        <Banner 
          src="/img/sample-hero-image-1.jpg"
          header="Build"
        />
      </Section>
      <Section>
        <Container>
          <Typography component="p">
            From the conceptualization stage until finishing stage and acceptance of the client,
            the company gives exemplary general construction services.
          </Typography>
        </Container>
      </Section>
    </Layout>
  )
}
